import { isRunningTests } from '../../utils/isRunningTests';
import { getHostWithPort } from '../../utils/url/url.utils';

import { getLocalEnv, getSharedConfig } from './config.shared';
import { AppConfig } from './config.types';
import { ConfigEnvs, envs } from './config.utils';

const envMappings = {
  localhost: envs.LOCAL,
  'play.dev.rikstv.no': envs.DEV,
  'play.uat.rikstv.no': envs.UAT,
  'play.pt.rikstv.no': envs.PT,
  'play.rikstv.no': envs.PROD,
};

const getEnv = () => {
  if (isRunningTests()) {
    return envs.TEST;
  }

  const env = envMappings[window.location.hostname as keyof typeof envMappings];

  if (!env) {
    console.warn('Unable to resolve environment from host name. Defaulting to LOCAL');
    return envs.LOCAL;
  }

  return env;
};

export default () => {
  const env = getEnv();
  const localEnv = env === envs.LOCAL ? getLocalEnv() : undefined;
  const configEnvs = new ConfigEnvs(env, localEnv);
  const sharedConfig = getSharedConfig(configEnvs);

  const Config: AppConfig = {
    ...sharedConfig,
    basePageTitle: 'TV på PC & Mac - Se TV i nettleseren din her | RiksTV',
    baseApplicationUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://play.rikstv.no',
      [envs.PT]: 'https://play.pt.rikstv.no',
      [envs.TEST]: 'https://play.dev.rikstv.no',
      [envs.UAT]: 'https://play.uat.rikstv.no',
      [envs.DEV]: 'https://play.dev.rikstv.no',
      [envs.LOCAL]: 'https://localhost:40801',
    }),
    iosApp: {
      appName: 'RiksTV',
      appStoreId: '787496930',
      link: 'https://itunes.apple.com/no/app/rikstv/id787496930',
    },
    androidApp: {
      appName: 'RiksTV',
      package: 'app-id=no.rikstv.app',
      link: 'https://play.google.com/store/apps/details?id=no.rikstv.app',
    },
    player: {
      ...sharedConfig.player,
      streamKick: {
        title: 'Avspillingen stoppet',
        message1: 'Avspillingen har blitt pauset fordi dere ser på mer enn to strømmer samtidig.',
        message2: 'Trykk "play" for å fortsette å se.',
      },
      muxEnvironmentKey: configEnvs.forEnv({
        [envs.PROD]: 'e5ante71p0ohduecfrcdnclb9',
        [envs.PT]: 'ntl10ipccqmkohckhdv530ure',
        [envs.TEST]: 'ntl10ipccqmkohckhdv530ure',
        [envs.UAT]: 'ntl10ipccqmkohckhdv530ure',
        [envs.DEV]: 'ntl10ipccqmkohckhdv530ure',
        [envs.LOCAL]: 'ntl10ipccqmkohckhdv530ure',
      }),
    },
    application: 'RiksTV',
    request: {
      appId: `RiksTV-Browser/${sharedConfig.appVersion}`,
    },
    sentry: {
      DSN: 'https://f6315f24a7b14a8e91c3a45919dc8edd@o575998.ingest.sentry.io/5783706',
      projectName: 'rikstv-web-tv',
      envName: configEnvs.forEnv({
        [envs.PROD]: 'prod',
        [envs.PT]: 'pt',
        [envs.TEST]: 'test',
        [envs.UAT]: 'uat',
        [envs.DEV]: 'dev',
        [envs.LOCAL]: 'local',
      }),
    },
    logToServer: configEnvs.forEnv({
      [envs.PROD]: { enabled: true, enableForPercentage: 50 },
      [envs.PT]: { enabled: true },
      [envs.TEST]: { enabled: false },
      [envs.UAT]: { enabled: false },
      [envs.DEV]: { enabled: false },
      [envs.LOCAL]: { enabled: false },
    }),
    cast: {
      brand: 'RiksTV',
      namespace: 'urn:x-cast:no.rikstv',
      receiverApplicationId: configEnvs.forEnv({
        [envs.PROD]: 'BB0E2150',
        [envs.PT]: '5F2919FE',
        [envs.TEST]: 'A2B80C24',
        [envs.UAT]: 'BE5489B9',
        [envs.DEV]: 'DB662BBB',
        [envs.LOCAL]: '5F2919FE',
      }),
    },
    webSocketUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://ws.rikstv.no:443/companion',
      [envs.PT]: 'https://ws.rikstv.no:443/companion',
      [envs.TEST]: 'https://ws.uat.rikstv.no:443/companion',
      [envs.UAT]: 'https://ws.uat.rikstv.no:443/companion',
      [envs.DEV]: 'https://ws.uat.rikstv.no:443/companion',
    }),
    share: {
      url: '',
    },
    vergic: {
      psId: '',
      url: '',
    },
    auth: {
      clientId: 'no.rikstv.play',
      stsUrl: configEnvs.forEnv({
        [envs.PROD]: 'https://login.rikstv.no/3/',
        [envs.PT]: 'https://login.rikstv.no/3/',
        [envs.TEST]: 'https://login.uat.rikstv.no/3/',
        [envs.UAT]: 'https://login.uat.rikstv.no/3/',
        [envs.DEV]: 'https://login.dev.rikstv.no/3/',
      }),
      scope: 'openid urn:rikstv:app offline_access',
      oidcCallbackUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}/app/callback`),
      oidcSilentRenewUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}/silent-renew.html`),
      oidcPostLogoutUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}`),
      tokenExpirationBuffer: configEnvs.forEnvWithDefault(
        {
          [envs.PROD]: 12 * 3600,
          [envs.PT]: 12 * 3600,
        },
        60
      ),
    },
    analytics: {
      targetId: '',
    },
    interAppPaths: [],
  };

  return { Config, configEnvs };
};
